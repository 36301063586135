export enum AccountStatus {
    Cancelled = -1,
    Inactive = 0,
    Active = 1,
    Professional = 2,
    Specialization = 3,
    Personal = 4,
    Activation = 11,
    NonActivatedProfessional = 12,
    NonActivatedSpecialization = 13,
    NonActivatedPersonal = 14,
    RecruiterActivation = 21,
    RecruiterNonActivatedProfessional = 22,
    RecruiterNonActivatedPersonal = 24,
    MobileStepFour = 102,
    MobileStepThree = 103,
    MobileStepTwo = 104,
    MobileNonActivatedStepFour = 112,
    MobileNonActivatedStepThree = 113,
    MobileNonActivatedStepTwo = 114,
}
